import { Fragment } from 'react'

import { useEffect, useState } from 'react'

import {
  HistoryControllerProps,
  hthunkprops,
  secureCompany,
  Vehicle,
} from '../../api/types'

import '../../styles/HistoryList.css'
import { useSelector } from 'react-redux'

import {
  Box,
  Button,
  CircularProgress,
  Stack,
  TextField,
  Typography,
} from '@mui/material'

import { RootState } from '../../app/store'

import { fetchHistGeolocations } from '../../redux_features/geolocations/histgeolocationSlice'
import { useAppDispatch } from '../../app/hooks'

import HistoryTimeline from '../VehicleHistoryTimeline'

import { selectAllVehicles } from '../../redux_features/vehicles/vehicleSlice'
import RightDrawer from '../RightDrawer'

const offset = new Date().getTimezoneOffset() * 1000 * 60

export const getLocalDate = (value: Date) => {
  const offsetDate = new Date(value).valueOf() - offset
  const date = new Date(offsetDate).toISOString()
  return date.substring(0, 16)
}

export const VehicleHistoryController = (props: HistoryControllerProps) => {
  const dispatch = useAppDispatch()
  const vehicles = useSelector(selectAllVehicles)
  const [now] = useState<Date>(new Date())
  const [startDate, setStartDate] = useState<Date>(
    new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0),
  )
  const [endDate, setEndDate] = useState<Date>(new Date())

  const [marker, setmarker] = useState<google.maps.Marker>()
  const [selectCustom, setCustomDate] = useState<string>('Today')
  const [polyline, setPolyline] = useState<google.maps.Polyline>()
  const historygeolocationstatus = useSelector(
    (state: RootState) => state.histgeolocations.status,
  )
  const historygeolocations = useSelector(
    (state: RootState) => state.histgeolocations.histgeolocations,
  )

  const sessionToken = useSelector(
    (state: RootState) => state.session.userdata.accessToken,
  )
  const organizationId = useSelector(
    (state: RootState) => state.session.userdata.organizationId,
  )
  useEffect(() => {
    if (historygeolocationstatus === 'idle') updateDates({ startDate, endDate })
  }, [historygeolocationstatus])

  useEffect(() => {
    removePolylines()
    if (historygeolocationstatus === 'succeeded') renderPolylines()

    return () => {
      removePolylines() // to remove when component is unrendered/closed
    }
  }, [historygeolocationstatus])

  const updateStart = async (difference: number) => {
    if (difference === 0) {
      let startDate = new Date()
      let endDate = new Date()

      startDate.setHours(0)
      startDate.setMinutes(0)
      startDate.setSeconds(0)

      setEndDate(endDate)
      setStartDate(startDate)
      updateDates({ startDate, endDate })
    }

    if (difference === 1) {
      let startDate = new Date()
      let endDate = new Date()

      startDate.setDate(startDate.getDate() - difference)
      endDate.setDate(endDate.getDate() - difference)

      startDate.setHours(0)
      startDate.setMinutes(0)
      startDate.setSeconds(0)

      endDate.setHours(23)
      endDate.setMinutes(59)
      endDate.setSeconds(59)

      setEndDate(endDate)
      setStartDate(startDate)
      updateDates({ startDate, endDate })
    }
  }

  useEffect(() => {
    polyline?.setMap(props.map!)
  }, [polyline])

  useEffect(() => {
    updateDates({ startDate, endDate })
  }, [props.device])

  const ranges = ['Today', 'Yesterday', 'Custom']

  const selectRange = (e: any) => {
    setCustomDate(e.target.value)
    switch (e.target.value) {
      case 'Today':
        updateStart(0)
        break
      case 'Yesterday':
        updateStart(1)
        break

      default:
        break
    }
  }

  const updateDates = async (e: { startDate: Date; endDate: Date }) => {
    const secCompany: secureCompany = {
      token: sessionToken,
      organizationId: organizationId,
    }
    const hprops: hthunkprops = {
      company: secCompany,
      deviceId: props.device,
      startDate: e.startDate,
      endDate: e.endDate,
    }

    await dispatch(fetchHistGeolocations(hprops))
  }

  //  Do not delete this function, it is used to save the history to a json file
  const saveToJson = () => {
    const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(
      JSON.stringify(historygeolocations),
    )}`
    const link = document.createElement('a')
    link.href = jsonString
    link.download = 'data.json'

    link.click()
  }

  const removePolylines = () => {
    console.log('removePolylines pending')
    // setOpenHistoryDrawer(false)
    polyline?.setMap(null)
  }

  let renderPolylines = () => {
    //iterating by device
    let path_coordinates: google.maps.LatLngLiteral[] = []
    let markers
    if (historygeolocationstatus === 'succeeded')
      //TODO no need markers array improve this, get rid of markers and return marker

      markers = historygeolocations.map((element: any, i: number) => {
        const mpoint: google.maps.LatLngLiteral = {
          lat: Number(element.latitude),
          lng: Number(element.longitude),
        }
        path_coordinates.push(mpoint)
      }) // map loop

    const lineSymbol = {
      path: google.maps.SymbolPath.BACKWARD_CLOSED_ARROW, //this is depending on the sorting really
    }

    const polyline = new google.maps.Polyline({
      path: path_coordinates,
      geodesic: true,
      strokeColor: vehicles.find(
        (vehic: Vehicle) => vehic?.device?.id === props.device,
      )?.pathColor,
      strokeOpacity: 1,
      strokeWeight: 3,
      icons: [
        {
          icon: lineSymbol,
          offset: '100%',
          repeat: '100px',
        },
      ],
    })

    setPolyline(polyline)
  }

  const RemoveAndClear = () => {
    props.handleCloseButton()
    removePolylines()
  }
  return (
    <RightDrawer anchor={'right'} close={RemoveAndClear}>
      <Box className="p-2 bg-white rounded-lg shadow-md mb-4">
        <Typography variant="h6" className="font-semibold mb-2">
          {vehicles.find((item) => item.device?.id === props.device)?.name!}
        </Typography>

        <Fragment>
          <Stack direction="row" spacing={2} alignItems={'center'}>
            <Typography style={{ marginLeft: 10 }}>Date Range</Typography>
            <select
              key={1}
              id="drivers"
              onChange={selectRange}
              style={{ marginLeft: 10, minHeight: 35, borderRadius: 5 }}
            >
              {ranges.map((range: string, index: number) => {
                return (
                  <Fragment key={index}>
                    <option value={range}>{range}</option>
                  </Fragment>
                )
              })}
            </select>
          </Stack>
          {historygeolocationstatus === 'succeeded' ? (
            selectCustom === 'Custom' && (
              <>
                {/* <Button onClick={saveToJson}>save</Button> */}
                <div>
                  <TextField
                    value={getLocalDate(startDate)}
                    size="small"
                    id="StartDate"
                    type="datetime-local"
                    style={{
                      width: 215,
                      borderRadius: '0.25rem',
                      margin: 10,
                    }}
                    onChange={(e) => {
                      const startdate = new Date(e.target.value)
                      setStartDate(startdate)
                    }}
                  />
                </div>
                <div>
                  <TextField
                    value={getLocalDate(endDate)}
                    size="small"
                    id="EndDate"
                    type="datetime-local"
                    style={{ width: 215, margin: 10 }}
                    onChange={(e) => {
                      const enddate = new Date(e.target.value)
                      setEndDate(enddate)
                    }}
                  />
                </div>

                <Button
                  size="small"
                  variant="contained"
                  onClick={async () => {
                    const range = endDate.getDate() - startDate.getDate()
                    if (range <= 7) updateDates({ startDate, endDate })
                    else
                      alert('Please select a Date Window no bigger than a week')
                  }}
                  style={{ margin: 10 }}
                >
                  Update Map
                </Button>
              </>
            )
          ) : (
            <div>
              <CircularProgress />
            </div>
          )}
          <Button onClick={removePolylines} style={{ marginTop: '10' }}>
            Clear Map
          </Button>

          <HistoryTimeline
            device={props.device}
            onMouseOver={(lat: any, lon: any) => {
              const mpoint: google.maps.LatLngLiteral = {
                lat: Number(lat),
                lng: Number(lon),
              }

              const row_marker = new google.maps.Marker({
                map: props.map,
                position: mpoint,
              })
              setmarker(row_marker)
            }}
            onMouseOut={(lat: any, lon: any) => {
              marker?.setMap(null)
            }}
          />
        </Fragment>
        {/* </Card> */}
      </Box>
    </RightDrawer>
  )
}

export default VehicleHistoryController
