import React, { useEffect, useState, useCallback } from 'react'
import {
  Typography,
  Button,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Radio,
  RadioGroup,
  FormControlLabel,
  Avatar,
  Checkbox,
  CircularProgress,
  ListItemAvatar,
  TextField,
} from '@mui/material'
import { GeofenceWithFigure } from '../../api/types'
import { selectAllVehicles } from '../../redux_features/vehicles/vehicleSlice'
import { useSelector } from 'react-redux'
import useWindowSize from '../../customHooks/useWindowSize'
import {
  getGeofencesVehicles,
  updateGeofenceVehicles,
} from '../../api/geofences'
import { RootState } from '../../app/store'
import { useAppDispatch } from '../../app/hooks'

export type GeofenceEditProps = {
  cancelEditing: () => void
  updateGeofence: (editedName: string) => Promise<void>
  geofenceToEdit: GeofenceWithFigure | undefined
}

const GeofenceEdit: React.FC<GeofenceEditProps> = ({
  cancelEditing,
  updateGeofence,
  geofenceToEdit,
}) => {
  const dispatch = useAppDispatch()
  const [isLoading, setLoading] = useState(true)
  const [vehiclesListStatus, setVehiclesListStatus] = useState(false)
  const [editedGeofenceName, setEditedGeofenceName] = useState(
    geofenceToEdit?.name || '',
  )
  const [geofenceLinkedVehicles, setLinkedVehicles] = useState<string[]>([])
  const windowSize = useWindowSize()

  const vehicles = useSelector(selectAllVehicles)
  const { organizationId, accessToken: sessionToken } = useSelector(
    (state: RootState) => state.session.userdata,
  )

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditedGeofenceName(e.target.value)
  }

  const handleToggle = useCallback(
    (value: string) => () => {
      setLinkedVehicles((prev) => {
        const newChecked = [...prev]
        const currentIndex = newChecked.indexOf(value)
        if (currentIndex === -1) {
          newChecked.push(value)
        } else {
          newChecked.splice(currentIndex, 1)
        }
        return newChecked
      })
      setVehiclesListStatus(true)
    },
    [],
  )

  // const fetchGeofenceVehicles = useCallback(async () => {
  //   if (!geofenceToEdit?.id) return

  //   try {
  //     const data = await getGeofencesVehicles(
  //       geofenceToEdit.id,
  //       organizationId,
  //       sessionToken,
  //     )
  //     setLinkedVehicles(data)
  //   } catch (error) {
  //     console.error('Error fetching geofence vehicles:', error)
  //     alert('There was an error retrieving information, please try again.')
  //     cancelEditing()
  //   } finally {
  //     setLoading(false)
  //   }
  // }, [geofenceToEdit?.id, organizationId, sessionToken, cancelEditing])

  const handleUpdate = async () => {
    // if (vehiclesListStatus) {
    //   try {
    //     await updateGeofenceVehicles(
    //       { vehicles: geofenceLinkedVehicles.map((id) => ({ id })) },
    //       geofenceToEdit?.id!,
    //       organizationId,
    //       sessionToken,
    //     )
    //   } catch (error) {
    //     console.error('Error updating geofence vehicles:', error)
    //     alert(
    //       'There was an error updating the vehicles information, please try again later',
    //     )
    //     return
    //   }
    // }

    if (editedGeofenceName) {
      await updateGeofence(editedGeofenceName)
    }
  }

  // useEffect(() => {
  //   fetchGeofenceVehicles()
  // }, [fetchGeofenceVehicles])

  return (
    <div style={{ marginLeft: 10 }}>
      <TextField
        label="Name"
        value={editedGeofenceName}
        onChange={handleNameChange}
        fullWidth
        margin="normal"
      />

      <Typography style={{ marginTop: 10 }}>Geofence type</Typography>
      <RadioGroup
        defaultValue={geofenceToEdit?.type || 'Rectangle'}
        name="geofence-type"
      >
        <FormControlLabel
          value="Rectangle"
          control={<Radio />}
          label="Rectangle"
        />
        <FormControlLabel value="Polygon" control={<Radio />} label="Polygon" />
      </RadioGroup>

      <Typography style={{ marginTop: 20 }}>Alert Type</Typography>
      <RadioGroup defaultValue="onentrance" name="alert-type">
        <FormControlLabel
          value="onentrance"
          control={<Radio />}
          label="On Entrance"
        />
        <FormControlLabel value="onexit" control={<Radio />} label="On Exit" />
        <FormControlLabel value="onboth" control={<Radio />} label="On Both" />
      </RadioGroup>

      <List
        dense
        sx={{
          width: '100%',
          maxWidth: 360,
          bgcolor: 'background.paper',
          overflow: 'auto',
          height: windowSize.height / 2.5,
        }}
      >
        {vehicles.map((item) => (
          <ListItem
            key={item.id}
            secondaryAction={
              <Checkbox
                edge="end"
                onChange={handleToggle(item.id!)}
                checked={geofenceLinkedVehicles.includes(item.id!)}
                inputProps={{ 'aria-labelledby': item.name }}
              />
            }
            disablePadding
          >
            <ListItemButton>
              <ListItemAvatar>
                <Avatar alt={`Avatar for ${item.name}`} />
              </ListItemAvatar>
              <ListItemText id={item.name} primary={item.name} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>

      <Button onClick={handleUpdate}>Update</Button>
      <Button onClick={cancelEditing}>Cancel</Button>
    </div>
  )
}

export default GeofenceEdit
