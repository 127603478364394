import {
  deleteDriver,
  driverUpdated,
  fetchDrivers,
  selectAllDrivers,
} from './driverSlice'
import { Key, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useAppDispatch } from '../../app/hooks'
import type { RootState } from '../../app/store'

import { Driver, secureDriver, secureCompany, Vehicle } from '../../api/types'
import React from 'react'

// import './driverfeat.css'

import {
  Avatar,
  Button,
  CircularProgress,
  Container,
  List,
  MenuItem,
  Paper,
  Popover,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'
import useWindowSize from '../../customHooks/useWindowSize'

import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'

import { selectAllVehicles } from '../vehicles/vehicleSlice'
import ConfirmationWindow from '../../components/ConfirmationWindow'
import { AddDriver } from './AddDriverModal'

function DriverRow(item: any) {
  const dispatch = useAppDispatch()
  const [open, setOpen] = useState(null)

  const [onEditRow, setOnEditRow] = useState(false)
  const [name, setName] = useState(item.name)
  const allvehicles = useSelector(selectAllVehicles)
  const [estado, setEstado] = useState(item.status)
  const sessionToken = useSelector(
    (state: RootState) => state.session.userdata.accessToken,
  )
  const organizationId = useSelector(
    (state: RootState) => state.session.userdata.organizationId,
  )
  const editRow = (e: any) => {
    e.preventDefault()
    setOnEditRow(!onEditRow)
  }
  const role = useSelector((state: RootState) => state.session.userdata.roles)

  const onNameChanged = (e: any) => {
    setName(e.target.value)
  }
  const handleOpenMenu = (event: any) => {
    setOpen(event.currentTarget)
  }

  const handleCloseMenu = () => {
    setOpen(null)
  }

  const onUpdateDriverClicked = async () => {
    //spread and update elements here
    let tempDriver: Driver = { ...item }
    tempDriver.name = name

    if (tempDriver) {
      const obj: secureDriver = {
        driver: tempDriver,
        token: sessionToken,
        organizationId,
      }

      try {
        await dispatch(driverUpdated(obj))
      } catch (err) {
      } finally {
        setOnEditRow(false)
      }
    }
  }

  const onDeactivateDriverClicked = async () => {
    //spread and update elements here
    let tempDriver: Driver = { ...item }

    if (tempDriver) {
      const obj: secureDriver = {
        driver: tempDriver,
        token: sessionToken,
        organizationId,
      }

      try {
        await dispatch(deleteDriver(obj))
      } catch (err) {
      } finally {
        setOnEditRow(false)
      }
    }
  }

  const onRemoveClicked = async () => {
    let tempDriver: Driver = { ...item }

    const obj: secureDriver = {
      driver: tempDriver,
      token: sessionToken,
      organizationId,
    }

    try {
      await dispatch(deleteDriver(obj))
    } catch (err) {
    } finally {
    }
  }

  return (
    <React.Fragment>
      {!onEditRow && (
        <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
          <TableCell>
            <Stack direction="row" alignItems="center" spacing={2}>
              <Avatar alt={item.name} sx={{ backgroundColor: '#dfe2e7' }} />
              <Typography noWrap>{item.name}</Typography>
            </Stack>
          </TableCell>
          <TableCell>
            <Typography>
              {
                allvehicles.find(
                  (value: Vehicle) => value.driver?.id === item.id,
                )?.name
              }
            </Typography>
          </TableCell>

          <TableCell>
            <Tooltip title="Edit Driver">
              <Button
                sx={{ marginTop: '-5px' }}
                onClick={handleOpenMenu}
                disabled={role[0] == 'admin' ? false : true} //#TODO
              >
                <MoreVertOutlinedIcon />
              </Button>
            </Tooltip>
          </TableCell>

          <Popover
            open={Boolean(open)}
            anchorEl={open}
            onClose={handleCloseMenu}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            transformOrigin={{ vertical: 'top', horizontal: 'left' }}
            PaperProps={{
              sx: {
                p: 1,
                width: 140,
                '& .MuiMenuItem-root': {
                  px: 1,
                  typography: 'body2',
                  borderRadius: 0.75,
                },
              },
            }}
          >
            <MenuItem
              onClick={() => {
                setOnEditRow(true)
                setOpen(null)
              }}
            >
              <EditOutlinedIcon sx={{ marginRight: 2 }} />
              Edit
            </MenuItem>

            <MenuItem sx={{ color: 'error.main' }}>
              <ConfirmationWindow
                message="Are you sure you want to delete this Driver?"
                callbackFunction={() => {
                  const vehiculo = allvehicles.find(
                    (value: Vehicle) => value.driver?.id === item.id,
                  )
                  if (vehiculo)
                    alert(
                      `You must  to desassign the Driver to ${vehiculo.name} vehicle before you can delete it `,
                    )
                  else {
                    onDeactivateDriverClicked()
                    setOpen(null)
                  }
                }}
              />
            </MenuItem>
          </Popover>
        </TableRow>
      )}
      {onEditRow && (
        <TableRow>
          <TableCell>
            <TextField
              value={name}
              size="small"
              onChange={onNameChanged}
            ></TextField>
          </TableCell>
          <TableCell>
            <Typography>
              {
                allvehicles.find(
                  (value: Vehicle) => value.driver?.id === item.id,
                )?.name
              }
            </Typography>
          </TableCell>

          <TableCell>
            <div>
              <Button
                onClick={onUpdateDriverClicked}
                style={{ margin: '20px' }}
                className="generic-button2"
              >
                save
              </Button>
            </div>
            <div>
              <Button
                onClick={() => {
                  setOnEditRow(false)
                }}
                style={{ margin: '20px' }}
                className="generic-button2"
              >
                cancel
              </Button>
            </div>
          </TableCell>
        </TableRow>
      )}
    </React.Fragment>
  )
}

function DriversList() {
  const { width, height } = useWindowSize()
  const dispatch = useAppDispatch()

  const error = useSelector((state: RootState) => state.drivers.error)
  const [onEditDriver, setOnEditDriver] = useState(false)
  const [searchTerm, setSearchterm] = useState<string>('')
  const [nameSearch, setNameSearch] = useState<string>('')

  const drivers: Driver[] = useSelector(selectAllDrivers)
  const driverStatus = useSelector((state: RootState) => state.drivers.status)
  const sessionToken = useSelector(
    (state: RootState) => state.session.userdata.accessToken,
  )
  const organizationId = useSelector(
    (state: RootState) => state.session.userdata.organizationId,
  )

  useEffect(() => {
    if (driverStatus === 'idle') {
      //add code statuts in

      const secCompany: secureCompany = {
        token: sessionToken,
        organizationId: organizationId,
      }
      dispatch(fetchDrivers(secCompany))
    }
  }, [driverStatus, dispatch])

  const handleSearchDriver = (val: any) => {
    // find out why there is not target after specify type
    const str: string = val.target.value

    setSearchterm(str)
  }

  let content

  if (driverStatus === 'loading') {
    //content = <Spinner text="Loading..." />
    content = <CircularProgress />
  } else if (driverStatus === 'succeeded') {
    content = drivers
      .filter((val: Driver) => {
        if (searchTerm === '') {
          return val
        } else if (val.name.toLowerCase().includes(searchTerm.toLowerCase())) {
          return val
        } else return null
      })
      .map((chofer: Driver, index: number) => {
        return <DriverRow {...chofer} key={chofer.id} />
      })
  } else {
    content = <>{error}</>
  }

  return (
    <>
      <Paper
        sx={{
          height: height - height * 0.15,
          width: '100%',
          overflow: 'scroll',
        }}
      >
        <Container
          sx={{
            minWidth: 800,
            maxHeight: useWindowSize().height,
            overflow: 'scroll',
          }}
        >
          <Typography variant="h4" gutterBottom>
            Drivers
          </Typography>
          <Stack direction={'row'} style={{ justifyContent: 'space-between' }}>
            <TextField
              size="small"
              placeholder="Driver Name"
              onChange={handleSearchDriver}
              style={{ marginTop: '10px' }}
            ></TextField>

            <AddDriver />
          </Stack>
          <TableContainer>
            <Table
              stickyHeader
              aria-label="sticky table"
              style={{ verticalAlign: 'baseline' }}
            >
              <TableHead>
                <TableCell>Driver Name</TableCell>
                <TableCell>Vehicle Name</TableCell>
                <TableCell>Edit</TableCell>
              </TableHead>

              <TableBody>{content}</TableBody>
            </Table>
          </TableContainer>
        </Container>
      </Paper>
    </>
  )
}

export default DriversList
