import MapJS from './MapJS'
import { MapProps, secureCompany } from '../../api/types'
import React, { useEffect } from 'react'
import { fetchLiveGeolocations } from '../../redux_features/geolocations/geolocationSlice'
import { useAppDispatch } from '../../app/hooks'
import { useSelector } from 'react-redux'
import { RootState } from '../../app/store'

const MapJSContainer = () => {
  const dispatch = useAppDispatch()
  const organizationId = useSelector(
    (state: RootState) => state.session.userdata.organizationId,
  )
  const accessToken = useSelector(
    (state: RootState) => state.session.userdata.accessToken,
  )

  const tokenexpDate = useSelector(
    (state: RootState) => state.session.userdata.idtoken['exp'],
  )

  const getLiveData = async () => {
    console.log('polling')
    const secCompany: secureCompany = {
      token: accessToken,
      organizationId: organizationId,
    }
    await dispatch(fetchLiveGeolocations(secCompany))
  }

  useEffect(() => {
    console.log('getting data')
    getLiveData()
  }, [])

  let zoom
  let centerp: google.maps.LatLngLiteral
  centerp = {
    lat: 26.25979,
    lng: -80.08094,
  }
  zoom = 3
  const mapProps: MapProps = {
    center: centerp,
    zoom: zoom,
    height: '90vh',
    width: '100%',
    //TODO if no data is coming could crash substitute by ? in the future
    cssstyle: 'map',
    mapstyle: 'default',
    // markerClicked: () => {}, //TODO remove
    enable_controller: true,
  }

  return (
    <div style={{ paddingTop: '0px' }}>
      <MapJS {...mapProps} />
    </div>
  )
}

export default MapJSContainer
