import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useTheme } from '@mui/material'
import { StatCard } from './StatCard'
import {
  LocalShipping as VehicleIcon,
  Person as DriverIcon,
  Speed as SpeedIcon,
  LocationOn as LocationIcon,
} from '@mui/icons-material'
import {
  fetchVehicles,
  selectAllVehicles,
} from '../../redux_features/vehicles/vehicleSlice'
import {
  fetchDrivers,
  selectAllDrivers,
} from '../../redux_features/drivers/driverSlice'
import { RootState } from '../../app/store'
import { useAppDispatch } from '../../app/hooks'
import { secureCompany } from '../../api/types'

export const TotalVehiclesWidget = () => {
  const theme = useTheme()
  const dispatch = useAppDispatch()
  const totalVehicles = useSelector(selectAllVehicles).length
  const vehicleStatus = useSelector((state: RootState) => state.vehicles.status)
  const sessionToken = useSelector(
    (state: RootState) => state.session.userdata.accessToken,
  )
  const organizationId = useSelector(
    (state: RootState) => state.session.userdata.organizationId,
  )

  useEffect(() => {
    if (vehicleStatus === 'idle') {
      const secCompany: secureCompany = {
        token: sessionToken,
        organizationId: organizationId!,
      }

      dispatch(fetchVehicles(secCompany))
    }
  }, [vehicleStatus, dispatch])

  return (
    <StatCard
      icon={<VehicleIcon />}
      title="Total Vehicles"
      value={totalVehicles.toString()}
      color={theme.palette.primary.main}
    />
  )
}

export const ActiveDriversWidget = () => {
  const theme = useTheme()
  const dispatch = useAppDispatch()
  const totalDrivers = useSelector(selectAllDrivers).length

  const driverStatus = useSelector((state: RootState) => state.drivers.status)
  const sessionToken = useSelector(
    (state: RootState) => state.session.userdata.accessToken,
  )
  const organizationId = useSelector(
    (state: RootState) => state.session.userdata.organizationId,
  )

  useEffect(() => {
    if (driverStatus === 'idle') {
      //add code statuts in

      const secCompany: secureCompany = {
        token: sessionToken,
        organizationId: organizationId!,
      }
      dispatch(fetchDrivers(secCompany))
    }
  }, [driverStatus, dispatch])

  return (
    <StatCard
      icon={<DriverIcon />}
      title="Active Drivers"
      value={totalDrivers.toString()}
      color={theme.palette.secondary.main}
    />
  )
}

export const AverageSpeedWidget = () => {
  const theme = useTheme()
  return (
    <StatCard
      icon={<SpeedIcon />}
      title="Avg. Speed"
      value="62 mph"
      color={theme.palette.success.main}
    />
  )
}

export const ActiveRoutesWidget = () => {
  const theme = useTheme()
  return (
    <StatCard
      icon={<LocationIcon />}
      title="Active Routes"
      value="15"
      color={theme.palette.warning.main}
    />
  )
}

// export const VehicleUtilizationWidget = () => {
//   return <>tVehicle Utli</>
// }

// export const VehicleStatusWidget = () => {
//   return <>tVhiecul status widget</>
// }

export const WidgetMap = {
  totalVehicles: TotalVehiclesWidget,
  activeDrivers: ActiveDriversWidget,
  averageSpeed: AverageSpeedWidget,
  activeRoutes: ActiveRoutesWidget,
  //   vehicleUtilization: VehicleUtilizationWidget,
  //   vehicleStatus: VehicleStatusWidget,
}
