import React, { useState } from 'react'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Modal from '@mui/material/Modal'
import DateRangeSelector from './DateRangeSelector'

import { shareItem } from '../api/insecurereq'
import { useSelector } from 'react-redux'
import { RootState } from '../app/store'
import TextField from '@mui/material/TextField'

import CloseIcon from '@mui/icons-material/Close'
import IconButton from '@mui/material/IconButton'

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
}

export type shareprops = {
  open: boolean
  handleClose: () => void
  vehicleId: string | undefined
}

export interface sharedVehicle {
  name: string
  vehicle: { id: string | undefined }
  startDate: Date
  endDate: Date
}

const URLDev = 'http://localhost:3000/sharedlocation/'
const URLProd = 'https://www.vermmis.com/sharedlocation/'

export const ShareLocationSettings = (props: shareprops) => {
  const [link, setlink] = useState('')
  const [shareID, setshareId] = useState('')

  /**BY YEYI */
  const [linkCopied, setLinkCopied] = useState<boolean>(false)

  const handleCopyLinkClick = () => {
    navigator.clipboard.writeText(link)
    setLinkCopied(true)
  }

  const sessionToken = useSelector(
    (state: RootState) => state.session.userdata.accessToken,
  )
  const organizationId = useSelector(
    (state: RootState) => state.session.userdata.organizationId,
  )

  let start = new Date()
  start.setHours(0)
  start.setMinutes(0)
  start.setSeconds(0)

  let end = new Date()
  end.setHours(23)
  end.setMinutes(23)

  const [startDate, setStartDate] = useState<Date>(start)
  const [endDate, setEndDate] = useState<Date>(end)

  //TODO

  const generateLink = (e: any) => {
    setStartDate(e.startDate)
    setEndDate(e.endDate)

    const sharedVehicle: sharedVehicle = {
      name: '',
      vehicle: { id: props.vehicleId },
      endDate,
      startDate,
    }

    const resultPromise = shareItem(
      'share',
      sharedVehicle,
      sessionToken,
      organizationId,
    ).then((response) => {
      setlink(URLProd + response['result']['id'])

      return response['result']
    })
  }

  return (
    <div>
      <Modal
        open={props.open}
        // onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ borderRadius: 15 }}
      >
        <Box sx={style}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              borderBottom: 'solid',
              borderWidth: '1px',
              borderColor: '#dee2e6',
            }}
          >
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Share Vehicle Location
            </Typography>
            <Button
              variant="text"
              endIcon={<CloseIcon />}
              onClick={props.handleClose}
            />
          </div>

          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Let anyone with this link to see the Vehicle Location.
          </Typography>

          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Select a Date range
          </Typography>

          <DateRangeSelector
            handleUpdateButton={generateLink}
            ButtonString={'Create Link'}
          />

          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginTop: '10px',
            }}
          >
            {link && (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  alignItems: 'center',
                  position: 'relative',
                }}
              >
                <Button
                  size="small"
                  style={{
                    width: '102.54px',
                    position: 'absolute',
                    right: '0px',
                    top: '-40px',
                  }}
                  {...(linkCopied === true
                    ? { color: 'success' }
                    : { color: 'primary' })}
                  variant="outlined"
                  onClick={handleCopyLinkClick}
                >
                  {linkCopied === true ? 'Link Copied' : 'Copy Link'}
                </Button>
                <TextField
                  style={{ marginTop: '10px', width: '100%' }}
                  size="small"
                  value={link}
                  variant="outlined"
                />
              </div>
            )}
          </div>
        </Box>
      </Modal>
    </div>
  )
}
