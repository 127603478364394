import { useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState, AppDispatch } from '../../app/store'
// Import your session actions
import { refreshToken, deauthenticate } from './sessionSlice'

const useTokenManager = () => {
  const dispatch = useDispatch<AppDispatch>()
  const { userdata } = useSelector((state: RootState) => state.session)
  const { accessToken, refreshtoken: refreshTokenValue } = userdata

  const handleTokenRefresh = useCallback(async () => {
    try {
      if (!refreshTokenValue) {
        throw new Error('No refresh token available')
      }
      await dispatch(refreshToken(refreshTokenValue))
    } catch (error) {
      console.error('Token refresh failed:', error)
      await dispatch(deauthenticate('test'))
    }
  }, [dispatch, refreshTokenValue])

  useEffect(() => {
    if (!accessToken || !refreshTokenValue) return
    // Decode JWT to get expiration time
    const stringToken = accessToken as string
    const tokenData = JSON.parse(atob(stringToken.split('.')[1])) //TORO migrate to new Buffer thing
    const expirationTime = tokenData.exp * 1000 // Convert to millisecondsl
    const currentTime = Date.now()

    // Refresh 5 minutes before expiration
    const timeUntilRefresh = expirationTime - currentTime - 5 * 60 * 1000

    if (timeUntilRefresh <= 0) {
      handleTokenRefresh()
      return
    }

    const refreshTimer = setTimeout(handleTokenRefresh, timeUntilRefresh)
    return () => clearTimeout(refreshTimer)
  }, [accessToken, handleTokenRefresh])

  return { handleTokenRefresh }
}

export default useTokenManager
