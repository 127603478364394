import { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import {
  fetchGeofences,
  selectAllGeofences,
} from '../../redux_features/geofences/geofenceSlice'

export function useGeofenceFetching() {
  const dispatch = useAppDispatch()
  const geofencesStatus = useAppSelector((state) => state.geofences.status)
  const allGeofences = useAppSelector(selectAllGeofences)
  const sessionToken = useAppSelector(
    (state) => state.session.userdata.accessToken,
  )
  const organizationId = useAppSelector(
    (state) => state.session.userdata.organizationId,
  )

  useEffect(() => {
    if (geofencesStatus === 'idle') {
      const secCompany = {
        token: sessionToken,
        organizationId: organizationId,
      }
      dispatch(fetchGeofences(secCompany))
    }
  }, [geofencesStatus, dispatch, sessionToken, organizationId])

  return { allGeofences }
}
