import { Key, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { deleteVehicle, fetchVehicles, vehicleUpdated } from './vehicleSlice'
import { selectAllVehicles } from './vehicleSlice'

import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import { useAppDispatch } from '../../app/hooks'
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined'
import ConfirmationWindow from '../../components/ConfirmationWindow'
import NearMeIcon from '@mui/icons-material/NearMe'
import {
  Device,
  Driver,
  Vehicle,
  secureCompany,
  secureVehicle,
} from '../../api/types'
import type { RootState } from '../../app/store'

import { PopoverPicker } from './PopoverPicker'

import './vecfeat.css'

import React from 'react'

import { fetchDrivers, selectAllDrivers } from '../drivers/driverSlice'
import { fetchDevices, selectAllDevices } from '../devices/deviceSlice'
import useWindowSize from '../../customHooks/useWindowSize'

import { styled, useTheme } from '@mui/material/styles'
import {
  Button,
  Card as Paper,
  CircularProgress,
  Container,
  MenuItem,
  Popover,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  Card,
  Box,
} from '@mui/material'
import Tooltip from '@mui/material/Tooltip'

import { AddVehicle } from './AddVehicleModal'

//TODO make shit dynamico for both the cells,driver, and device
const DriverTooltipContent = ({ driver }: { driver: Driver }) => (
  <Card sx={{ p: 1, maxWidth: 300 }}>
    <Box>
      <Typography variant="subtitle2" color="primary">
        Driver Details
      </Typography>
      <Box sx={{ mt: 1 }}>
        <Typography variant="body2">Name: {driver.name}</Typography>
        <Typography variant="body2">
          LastName: {driver.lastName || ''}
        </Typography>

        <Typography variant="body2"></Typography>
      </Box>
    </Box>
  </Card>
)

export const VehicleRow = (item: Vehicle) => {
  const iconURL =
    'https://images-ea57cbc6-0253-11ed-b939-0242ac120002.s3.amazonaws.com/'
  const theme = useTheme()

  const [color, setColor] = useState(item.pathColor)
  const organizationId = useSelector(
    (state: RootState) => state.session.userdata.organizationId,
  )
  const alldrivers = useSelector(selectAllDrivers)

  const alldevices = useSelector(selectAllDevices)

  const sessionToken = useSelector(
    (state: RootState) => state.session.userdata.accessToken,
  )

  const dispatch = useAppDispatch()
  const [onEditRow, setOnEditRow] = useState(false)
  const [open, setOpen] = useState(null)
  const [vehicleName, setName] = useState(item.name)

  const [driver, setDriver] = useState<Driver | undefined>(item?.driver)
  const [device, setDevice] = useState<Device | undefined>(item?.device)
  const [iconState, seticonState] = useState(item.icon)

  const deviceStatus = useSelector((state: RootState) => state.devices.status)
  const driverStatus = useSelector((state: RootState) => state.drivers.status)
  // const role = useSelector(
  //   (state: RootState) => state.session.userdata.OrganizationUser.,
  // )
  const CssTextField = styled(TextField)({
    '& label.Mui-focused': {
      color: 'green',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'green',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'white',
      },
      '&:hover fieldset': {
        borderColor: 'white',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'green',
      },
    },
  })

  useEffect(() => {
    {
    }
    if (color === null) {
      setColor('black')
    }
  }, [])

  const editRow = (e: any) => {
    e.preventDefault()
    setOnEditRow(!onEditRow)
  }

  const onNameChanged = (e: any) => {
    setName(e.target.value)
  }

  const selectDriver = (e: any) => {
    e.preventDefault()

    if (e.target.value) {
      const driver = alldrivers.find(
        (driver: Driver) => driver.id === e.target.value,
      )

      setDriver(driver)
    } else {
      setDriver(undefined)
    }
  }

  const selectDevice = (e: any) => {
    e.preventDefault()

    if (e.target.value && e.target.value != 'None') {
      const device = alldevices.find(
        (device: Device) => device.id === e.target.value,
      )
      setDevice(device)
    } else {
      setDevice(undefined)
    }
  }
  const defaultNameSelect = (name: string) => {
    if (name === item.driver?.name) {
      return true
    } else return false
  }
  const defaultDeviceSelect = (id: string) => {
    if (id === item.device?.id) {
      return true
    } else return false
  }

  const onUpdateVehicleClicked = async () => {
    let tempname = ''
    if (vehicleName) tempname = vehicleName
    //TODO try to identify which element  changed verify REDUX behavior..
    const vehicUPdate: Partial<Vehicle> = { ...item }
    vehicUPdate.name = vehicleName
    vehicUPdate.driver = driver //find out why
    vehicUPdate.device = device
    vehicUPdate.pathColor = color
    vehicUPdate.icon = iconState

    // reactivate api call after testing
    if (vehicUPdate) {
      let obj: secureVehicle = {
        vehicle: vehicUPdate,
        token: sessionToken,
        organizationId: organizationId!,
      }
      try {
        await dispatch(vehicleUpdated(obj))
      } catch (err) {
      } finally {
        setOnEditRow(false)
      }
    }
  }

  const setIconFunc = (value: string) => {
    if (value) seticonState(value)
  }
  const handleOpenMenu = (event: any) => {
    setOpen(event.currentTarget)
  }

  const handleCloseMenu = () => {
    setOpen(null)
  }

  const validateandFetch = () => {
    if (driverStatus === 'idle') {
      const secCompany: secureCompany = {
        token: sessionToken,
        organizationId: organizationId!,
      }
      dispatch(fetchDrivers(secCompany))
    }
    if (deviceStatus === 'idle') {
      const secCompany: secureCompany = {
        token: sessionToken,
        organizationId: organizationId!,
      }
      dispatch(fetchDevices(secCompany))
    }
  }

  const deactivateVehicle = (e: any) => {
    let tempVehicl: Vehicle = { ...item }

    let obj: secureVehicle = {
      vehicle: tempVehicl,
      token: sessionToken,
      organizationId: organizationId!,
    }

    dispatch(deleteVehicle(obj))
  }
  // TODO add column selection
  return (
    <React.Fragment>
      {!onEditRow && (
        <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
          <TableCell>
            <Stack direction="row" alignItems="center" spacing={2}>
              {/* <Avatar
                alt={item.name}
                src={iconURL + iconState}
                sx={{ backgroundColor: '#dfe2e7' }}
              /> */}
              <NearMeIcon style={{ color: item.pathColor }} />
              <Typography noWrap>{item.name}</Typography>
            </Stack>
          </TableCell>

          <TableCell>
            {item.driver ? (
              <Tooltip
                title={<DriverTooltipContent driver={item.driver} />}
                placement="top"
                arrow
                enterDelay={1500} // Wait 1.5 seconds before showing
                leaveDelay={200} // Wait 0.2 seconds before hiding
                PopperProps={{
                  modifiers: [
                    {
                      name: 'offset',
                      options: {
                        offset: [0, -8],
                      },
                    },
                  ],
                }}
              >
                <Typography sx={{ cursor: 'pointer' }}>
                  {item.driver.name}
                </Typography>
              </Tooltip>
            ) : (
              <Typography>-</Typography>
            )}
          </TableCell>
          <TableCell>
            <Typography>{item.device ? item.device?.name : '-'}</Typography>
          </TableCell>
          <TableCell sx={{ maxWidth: 30 }}>
            <span
              className="swatch"
              style={{
                width: 28,
                marginLeft: 30,
                backgroundColor: color,
                maxWidth: 30,
                color: color,
                borderRadius: '3px',
                margin: 10,

                //  border: '3px solid #fff',
              }}
            >
              Ol
            </span>
          </TableCell>

          <TableCell>
            <Tooltip title=" Edit Vehicle">
              <Button
                sx={{ marginTop: '-5px' }}
                onClick={handleOpenMenu}
                // disabled={role[0] == 'admin' ? false : true} //#TODO
              >
                <MoreVertOutlinedIcon />
              </Button>
            </Tooltip>
          </TableCell>

          <Popover
            open={Boolean(open)}
            anchorEl={open}
            onClose={handleCloseMenu}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            transformOrigin={{ vertical: 'top', horizontal: 'left' }}
            PaperProps={{
              sx: {
                p: 1,
                width: 140,
                '& .MuiMenuItem-root': {
                  px: 1,
                  typography: 'body2',
                  borderRadius: 0.75,
                },
              },
            }}
          >
            <MenuItem
              onClick={() => {
                validateandFetch()
                setOnEditRow(true)
                setOpen(null)
              }}
            >
              <EditOutlinedIcon sx={{ marginRight: 2 }} />
              Edit
            </MenuItem>

            <MenuItem sx={{ color: 'error.main' }}>
              <ConfirmationWindow
                message="Are you sure you want to delete this Vehicle?"
                callbackFunction={() => {
                  if (item.device)
                    alert(
                      'You must  to desassign the tracking device to this vehicle before you can delete it ',
                    )
                  else {
                    deactivateVehicle(Event)

                    setOpen(null)
                  }
                }}
              />
            </MenuItem>
          </Popover>
        </TableRow>
      )}

      {onEditRow && (
        <>
          <TableRow
            style={{
              borderBottom: 'unset',
              height: 120,
              backgroundColor: '#f8fcff',
            }}
          >
            <TableCell>
              <Stack direction="row" alignItems="center" spacing={2}>
                {/* <IconPicker
                  url={iconURL + iconState}
                  selectIcon={setIconFunc}
                /> */}

                <TextField
                  size="small"
                  sx={{ borderColor: 'white', minWidth: 180 }}
                  defaultValue={item.name}
                  InputProps={{
                    readOnly: !onEditRow,
                  }}
                  onChange={onNameChanged}
                />
              </Stack>
            </TableCell>

            <TableCell>
              <select
                key={1}
                id="drivers"
                onChange={selectDriver}
                style={{
                  maxWidth: 150,

                  // height: 40,
                  borderRadius: 5,
                }}
              >
                <option value={undefined} selected={true}>
                  None
                </option>
                {alldrivers.map((driver: Driver, index: Key) => {
                  return (
                    <option
                      key={driver.id}
                      value={driver.id}
                      selected={defaultNameSelect(driver.name)}
                    >
                      {driver.name}
                    </option>
                  )
                })}
              </select>
            </TableCell>
            <TableCell>
              <select
                key={2}
                id="devices"
                onChange={selectDevice}
                style={{
                  borderRadius: 5,
                }}
              >
                <option value={undefined} selected={true}>
                  None
                </option>
                {alldevices.map((device: Device, index: Key) => {
                  return (
                    <option
                      key={device.id}
                      value={device.id}
                      selected={defaultDeviceSelect(device.id!)} //HERE
                    >
                      {device.name}
                    </option>
                  )
                })}
              </select>
            </TableCell>
            <TableCell>
              <PopoverPicker color={color} onChangecallback={setColor} />
            </TableCell>
            <TableCell>
              <div>
                <Button
                  variant="outlined"
                  onClick={onUpdateVehicleClicked}
                  size="small"
                >
                  save
                </Button>
              </div>

              <Button
                onClick={() => {
                  setOnEditRow(false)
                  setColor(item.pathColor ? item.pathColor : 'FFFF')
                  seticonState(item.icon)
                }}
              >
                cancel
              </Button>
            </TableCell>
          </TableRow>
        </>
      )}
    </React.Fragment>
  )
}

export const VehiclesList = () => {
  const dispatch = useAppDispatch()
  const [searchTerm, setSearchterm] = useState<string>('')
  const [nameSearch, setNameSearch] = useState<string>('')
  const { width, height } = useWindowSize()
  let content
  const vehicles = useSelector(selectAllVehicles)
  const vehicleStatus = useSelector((state: RootState) => state.vehicles.status)
  const error = useSelector((state: RootState) => state.vehicles.error)
  const sessionToken = useSelector(
    (state: RootState) => state.session.userdata.accessToken,
  )
  const organizationId = useSelector(
    (state: RootState) => state.session.userdata.organizationId,
  )

  useEffect(() => {
    if (vehicleStatus === 'idle') {
      const secCompany: secureCompany = {
        token: sessionToken,
        organizationId: organizationId!,
      }

      dispatch(fetchVehicles(secCompany))
    }
  }, [vehicleStatus, dispatch])

  const handleSearchVehicle = (val: any) => {
    // find out why there is not target after specify type
    const str: string = val.target.value
    setSearchterm(str)
  }

  if (vehicleStatus === 'loading') {
    content = <CircularProgress />
  } else if (vehicleStatus === 'succeeded') {
    content = vehicles

      .filter((val: Vehicle) => {
        if (searchTerm === '') {
          return val
        } else if (val.name.toLowerCase().includes(searchTerm.toLowerCase())) {
          return val
        } else return null
      })
      .filter((val: Vehicle) => {
        if (nameSearch === '') {
          return val
        } else if (
          val.driver?.name //TODO change this to val.Driver Name
            ?.toLowerCase()
            .includes(nameSearch.toLowerCase())
        ) {
          return val
        } else return null
      })
      //review sorting and editing
      .sort((a: Vehicle, b: Vehicle) => a.name.localeCompare(b.name))
      .map((vehiculo: Vehicle, index: number) => {
        return <VehicleRow {...vehiculo} key={vehiculo.id} />
      })
  } else if (vehicleStatus === 'failed') {
    content = <div>{error}</div>
  }

  return (
    <>
      <Paper
        sx={{
          height: height - height * 0.15,
          width: '100%',
          overflow: 'scroll',
        }}
      >
        <Container
          sx={{
            minWidth: 800,
            maxHeight: useWindowSize().height,
            overflow: 'scroll',
          }}
        >
          <Typography variant="h4" gutterBottom>
            Vehicles
          </Typography>
          <Stack direction={'row'} style={{ justifyContent: 'space-between' }}>
            <TextField
              size="small"
              placeholder="Vehicle"
              onChange={handleSearchVehicle}
            ></TextField>
            <AddVehicle />
          </Stack>
          <TableContainer>
            <Table
              stickyHeader
              aria-label="sticky table"
              style={{ verticalAlign: 'baseline' }}
            >
              <TableHead>
                <TableCell>Vehicle Name</TableCell>
                <TableCell>Driver Name</TableCell>
                <TableCell>Device Name</TableCell>
                <TableCell>Route Color</TableCell>
                <TableCell>Edit</TableCell>
              </TableHead>
              <TableBody>{content}</TableBody>
            </Table>
          </TableContainer>
        </Container>
      </Paper>
    </>
  )
}
